.layout-colorscheme-menu {
  --logo-color: var(--primary-color);
  --menu-bg: var(--surface-overlay);
  --root-menuitem-text-color: var(--primary-color);
  --menuitem-text-color: var(--text-color);
  --menuitem-icon-color: var(--primary-color);
  --menuitem-hover-bg-color: var(--surface-hover);
  --popup-submenu-item-text-color: var(--text-color);
  --popup-submenu-item-icon-color: var(--primary-color);
  --popup-submenu-item-hover-bg-color: var(--surface-hover);
}
