/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system

/* Layout */
@import 'assets/layout/styles/layout/layout.scss';

/* PrimeNG */
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/@iconscout/unicons/css/line.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:nth-child(odd), 
p-table.alttable> div > div > table > tbody > tr:nth-child(odd),
div.p-treetable-scrollable-body.ng-star-inserted > table > tbody > tr:nth-child(odd),
p-table.paidalttable > div > div > table > tbody > tr:nth-child(odd), 
tr.scorecardtable:nth-child(odd) {
    background-color: #ffffff !important; /* Light gray for odd rows */
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase !important;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:nth-child(even),
p-table.alttable> div > div > table > tbody > tr:nth-child(even),
div.p-treetable-scrollable-body.ng-star-inserted > table > tbody > tr:nth-child(odd),
p-table.paidalttable > div > div > table > tbody > tr:nth-child(even),
tr.scorecardtable:nth-child(even) {
    background-color: #f5f3fd !important; /* White for even rows */
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase !important;
}
table > thead > tr > th, .p-treetable .p-treetable-tbody > tr > td, .p-datatable .p-datatable-tbody > tr > td{
    text-transform: uppercase;
    font-size: 12px;
}
.p-treetable-scrollable-header > div > table > thead > tr > th:nth-child(1){
    text-align: left;
    font-size: 12px;
  }
.p-treetable .p-treetable-tfoot > tr > td:nth-child(1){
    text-align: left;
    font-size: 12px;
}
table > tbody > tr > td.font-medium > div {
    text-align: left;
    font-size: 12px;
}
p-table.paidalttable> div > div > table > thead > tr> th:nth-child(2),
p-table.paidalttable> div > div > table > thead > tr> th:nth-child(3),
p-table.paidalttable> div > div > table > tbody > tr> td:nth-child(2),
p-table.paidalttable> div > div > table > tbody > tr> td:nth-child(3),
.text-right{
    text-align: right !important;
}
.text-left{
    text-align: left !important;
}

.p-datatable .p-datatable-thead > tr > th:nth-child(1),.p-datatable .p-datatable-thead > tr > th:nth-child(2), .p-datatable .p-datatable-thead > tr > th:nth-child(3){
    text-align: left;
    font-size: 12px;
}
.p-datatable .p-datatable-tbody > tr > td:nth-child(1),.p-datatable .p-datatable-tbody > tr > td:nth-child(2), .p-datatable .p-datatable-tbody > tr > td:nth-child(3){
    text-align: left;
    font-size: 12px;
}
.p-listbox .p-listbox-list {
    padding: 0px !important;
}
.align-items-baseline {
    align-items: start !important;
}
.p-datepicker table {
    font-size: 11px;
    margin: 0px;
}
.p-datepicker table td {
    padding: 0.2rem;
}
.m0{
    margin: 0px !important;
}
.p-datepicker table th {
    padding: 10px 0px 3px 0px;
}
.p-datepicker table th > span {
    font-size: 11px;
}
.p-datepicker .p-datepicker-header {
    padding: 0px;
}
.hotel-name-title{
    text-transform: uppercase;
    font-size: 16px;
}
.uppercase{
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
  }
  li.p-ripple.p-element.p-multiselect-item {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }

  .font-smaller{
    font-size: smaller;
  }

  #sticky {
    position: sticky;
    top: 43px;
    z-index: 2;
    // background: #f8fafc !important;
}